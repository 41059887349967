(async () => {  

  const [{default: React}, {createRoot}, {default: Application}] = await Promise.all([
    import('react'),
    import('react-dom/client'),
    import('./Application'),    
  ]);
  
  const root = createRoot(document.querySelector('#root')!);
  root.render(React.createElement(Application));
  
})();
